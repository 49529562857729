import axios from 'axios';

import { translatedMessage } from "./language.service";
import MapService from "./map.service";

let token;
let currentUser = null;
let currentProfile;
let currentCompany = null;
let companyUserPendingApproval = false;
let currentUserCountryCode;

let authenticatedInstance = null;

// const baseURL = 'https://app-staging.connected.art/api';
const baseURL = 'https://app-staging.connected.art/api';
// const baseURL = 'https://app.connected.art/api';

const ApiService = {
    getAuthenticatedInstance: () => authenticatedInstance,

    instance: axios.create({
        baseURL,
    }),

    initToken: () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const queryToken = params.get('_t');
        if (typeof queryToken !== 'undefined' && queryToken !== null && queryToken.length > 0) {
            localStorage.setItem('_t', queryToken);
        }

        token = localStorage.getItem('_t');
        authenticatedInstance = axios.create({
            baseURL,
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });

        return !!token;
    },

    persistToken: newToken => {
        token = newToken;
        localStorage.setItem('_t', token);
        authenticatedInstance = axios.create({
            baseURL,
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });
    },

    getToken: () => token,

    getCurrentUser: () => { return currentUser },

    setCurrentUser: user => { currentUser = user },

    getCurrentProfile: () => currentProfile,

    setCurrentProfile: profile => { currentProfile = profile },

    getCurrentCompany: () => currentCompany,

    setCurrentCompany: company => { currentCompany = company },

    getCompanyUserPendingApproval: () => companyUserPendingApproval,

    setCompanyUserPendingApproval: pendingApproval => { companyUserPendingApproval = pendingApproval },

    getBaseUrl: () => baseURL,

    getUserCountryCode: () => currentUserCountryCode,

    handleLogOut: (history) => {
        localStorage.removeItem('_t');
        localStorage.removeItem('_redirect');
        history.push('/login');
    },

    async setUserCountryCode() {
        if (!ApiService.getUserCountryCode()) {
            const navigatorLocation = await ApiService.getNavigatorLocation();
            // console.log(navigatorLocation);
            // console.log(this.getCurrentProfile());
            if (navigatorLocation) {
                // console.log(navigatorLocation)
                // const locationJSON = ApiService.getGoogleGeocode(navigatorLocation.lat, navigatorLocation.lng)
                // console.log(locationJSON);
            } else {

                fetch(`https://api.ipify.org?format=json`)
                    .then(async response => {
                        const data = await response.json();

                        fetch(`http://www.geoplugin.net/json.gp?ip=${data.ip.toString()}`)
                            .then(res => res.json())
                            .then(response => {
                                currentUserCountryCode = response.geoplugin_countryCode;
                            })
                            .catch((data, status) => {
                                console.log('Request failed');
                                currentUserCountryCode = null;
                            })
                    })
                    .catch(() => {
                        console.log('Request failed');
                        currentUserCountryCode = null;
                    })
            }
        }
    },

    getRedirectUrl: () => {
        return localStorage.getItem('_redirect');
    },

    setRedirectUrl: (path) => {
        if (typeof path !== 'undefined' && path !== null && path.length > 0 && path !== '/') {
            localStorage.setItem('_redirect', path);
        }
    },

    getFileByUuid: fileUuid => {
        return `${baseURL}/storage/file/${fileUuid}`;
    },

    getFileByProjectUrl: projectId => {
        return `${baseURL}/storage/file/project/${projectId}`;
    },

    getThumbnailByUrl: artworkId => {
        return `${baseURL}/storage/thumbnail/artwork/${artworkId}`;
    },

    getPrintPreviewByArtworkId: artworkId => {
        return `${baseURL}/storage/print_preview/artwork/${artworkId}`;
    },

    getSafe(fn, defaultVal) {
        try {
            return fn();
        } catch (e) {
            return defaultVal;
        }
    },

    getYesNoConverter(value) {
        return value ? translatedMessage("GENERAL.YES") : translatedMessage("GENERAL.NO");
    },

    nullIfEmptyString(value) {
        return value && value.trim() !== '' ? value.trim() : null
    },

    nullIfEmptyHTMLEditor(value) {
        let response = value.trim();
        response = response.replaceAll('&nbsp;', '').replaceAll('<p>', '').replaceAll('</p>', '').replaceAll('<br>', '').replaceAll('<br/>', '')
        
        console.log(response)

        return response && response.trim() !== '' ? response.trim() : null
    },    

    getYouTubeID(url) {
        var ID = '';
        url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        if (url[2] !== undefined) {
            ID = url[2].split(/[^0-9a-z_-]/i);
            ID = ID[0];
        }
        else {
            ID = url[0] ? url[0] : url;
        }
        return ID;
    },

    initCurrentUser() {
        return new Promise((resolve, reject) => {
            if (!ApiService.getCurrentUser()) {
                ApiService.getAuthenticatedInstance().get('/user/me')
                    .then(user => {
                        ApiService.setCurrentUser(user.data);
                        resolve(user.data);
                    })
            } else {
                resolve(ApiService.getCurrentUser());
            }
        })
    },

    initUser() {
        return new Promise((resolve, reject) => {
            if (!ApiService.getCurrentUser()) {
                ApiService.getAuthenticatedInstance().get('/user/me')
                    .then(user => {
                        ApiService.setCurrentUser(user.data);
                        ApiService.getAuthenticatedInstance().get(`/user/myProfile`)
                            .then(result => {
                                ApiService.setCurrentProfile({
                                    ...result.data.profile,
                                });
                                resolve(user.data);
                            });
                    })
            } else if (!ApiService.getCurrentProfile()) {
                ApiService.getAuthenticatedInstance().get(`/user/myProfile`)
                    .then(result => {
                        ApiService.setCurrentProfile({
                            ...result.data.profile,
                        });
                        resolve(ApiService.getCurrentUser());
                    });
            } else {
                resolve(ApiService.getCurrentUser());
            }
        })
    },

    initCurrentCompany() {
        return new Promise((resolve, reject) => {
            if (!ApiService.getCurrentCompany()) {
                ApiService.getAuthenticatedInstance().get('/company')
                    .then(response => {
                        console.log(response.data);
                        ApiService.setCurrentCompany(response.data.company);
                        if (response.data.isPendingApproval) {
                            ApiService.setCompanyUserPendingApproval(true);
                        }
                        resolve(response.data.company);
                    })
                    .catch(err => {
                        reject(err);
                    })
            } else {
                resolve(ApiService.getCurrentCompany());
            }
        })
    },

    upgrade(membershipName, interval) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`membership/upgrade`, {
                params: {
                    membershipName: membershipName,
                    interval: interval
                }
            })
                .then(response => resolve(response.data))
                .catch(err => {
                    reject(err);
                });
        });
    },

    updatePaymentMethod(language) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`payment/update-payment-info`, {
                params: {
                    language: language
                }
            })
                .then(response => resolve(response.data))
                .catch(err => {
                    reject(err);
                });
        });
    },

    downgrade(membershipName, interval) {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`membership/downgrade`, {
                params: {
                    membershipName: membershipName,
                    interval: interval
                }
            })
                .then(response => resolve(response.data))
                .catch(err => {
                    reject(err);
                });
        });
    },

    // SunEditor
    getSunEditorButtons: () => {
        return [
            ['undo', 'redo'],
            ['font', 'fontSize', 'formatBlock'],
            ['paragraphStyle', 'blockquote'],
            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
            ['fontColor', 'hiliteColor', 'textStyle'],
            ['removeFormat'],
            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list', 'lineHeight'],
            ['table', 'link'],
            // ['image', 'video'],
            ['showBlocks'],
            // ['codeView'],
            // ['preview', 'print'],
            // ['save', 'template'],
            // '/', Line break
        ];
    },

    getSunEditorNoButtons: () => {
        return [
            ['showBlocks'],
        ];
    },

    getEntityName: () => {
        return {
            EXHIBITION: "EXHIBITION",
            ARTWORK: "ARTWORK",
            GALLERY: "GALLERY",
            EXHIBITION_ARTWORK: "EXHIBITION_ARTWORK",
            GALLERY_ARTWORK: "GALLERY_ARTWORK",
            GALLERY_VIRTUAL_GALLERY: "GALLERY_VIRTUAL_GALLERY",
            CART: "CART",
            MEMBERSHIP: "MEMBERSHIP",
            VIRTUAL_GALLERY: "VIRTUAL_GALLERY",
            EXHIBITION_VIRTUAL_GALLERY: "EXHIBITION_VIRTUAL_GALLERY",
            EXHIBITION_AR_EXPERIENCE: "EXHIBITION_AR_EXPERIENCE",
            AR_EXPERIENCE: "AR_EXPERIENCE",
            COMPANY: "COMPANY",
            PROJECT: "PROJECT",
            AR_PORTAL: "AR_PORTAL",
            PARK: "PARK",
            PARK_ART: "PARK_ART"
        };
    },

    getSocialMediaAccountType: () => {
        return {
            LINKEDIN: "LINKEDIN",
            FACEBOOK: "FACEBOOK",
            YOUTUBE: "YOUTUBE",
            INSTAGRAM: "INSTAGRAM",
            PINTEREST: "PINTEREST",
            TWITTER: "TWITTER",
            SNAPCHAT: "SNAPCHAT",
            TIKTOK: "TIKTOK",
            PATREON: "PATREON"
        };
    },

    getTimeInterval: () => {
        return {
            MONTH: "MONTH",
            QUARTER: "QUARTER",
            YEAR: "YEAR"
        };
    },

    getSunEditorDefaultStyle: () => {
        return "font-family: Roboto; font-size: 15px; color: #000000;";
    },

    getSunEditorFonts: () => {
        return ['Arial', 'Comic Sans MS', 'Courier New', 'Impact',
            'Georgia', 'Open Sans', 'Roboto', 'sans-serif', 'tahoma', 'Trebuchet MS', 'Verdana'];
    },

    getNumberSeparatedByDots: (x) => {
        if (!x) {
            if (x !== 0) {
                return "N/A";
            }
        }

        if (x.toString().includes(".")) {
            let decimals = x.toString().split(".")[x.toString().split(".").length - 1].substring(0, 3);

            return x.toString().split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + decimals;
        }

        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00";
    },

    getFirstCharacters: (text, length) => {
        let newText = text;

        if (text.length > length) {
            newText = text.substring(0, length) + "...";
        }

        return newText;
    },

    parseDate: (date) => {
        if (typeof date === 'string') {
            var a = date.split(/[^0-9]/);

            return new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
        } else {
            return new Date(date);
        }
    },

    parseDateShort: (date) => {
        if (typeof date === 'string') {
            var a = date.split(/[^0-9]/);

            return new Date(a[0], a[1] - 1, a[2]);
        } else {
            return new Date(date);
        }
    },

    getMembershipList() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`memberships`)
                .then(response => {
                    let memberships = response.data._embedded.memberships.filter(item => item.name.toUpperCase() !== "CA")
                    resolve(memberships)
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getProfileName(profile) {
        if (profile.profileName) {
            return profile.profileName;
        } else {
            return profile.firstName + " " + profile.lastName;
        }
    },

    getSocialMediaAccountTypeList() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get(`enum/socialMediaAccountTypeList`)
                .then(response => {
                    let typeList = response.data;
                    typeList.map(item => {
                        item.icon = ApiService.getSocialMediaAccountIconByType(item.name);

                        return item;
                    })

                    typeList.sort((a, b) => {
                        var result;
                        a.name.toUpperCase() <= b.name.toUpperCase() ? result = -1 : result = 1
                        return result;
                    });

                    resolve(typeList)
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getSocialMediaAccountIconByType: (type) => {
        let iconName = "";

        switch (type) {
            case ApiService.getSocialMediaAccountType().FACEBOOK:
                iconName = "facebook-square";
                break;
            case ApiService.getSocialMediaAccountType().INSTAGRAM:
                iconName = "instagram";
                break;
            case ApiService.getSocialMediaAccountType().YOUTUBE:
                iconName = "youtube-square";
                break;
            case ApiService.getSocialMediaAccountType().LINKEDIN:
                iconName = "linkedin";
                break;
            case ApiService.getSocialMediaAccountType().PINTEREST:
                iconName = "pinterest-square";
                break;
            case ApiService.getSocialMediaAccountType().SNAPCHAT:
                iconName = "snapchat-square";
                break;
            case ApiService.getSocialMediaAccountType().TWITTER:
                iconName = "twitter-square";
                break;
            case ApiService.getSocialMediaAccountType().TIKTOK:
                iconName = "tiktok";
                break;
            case ApiService.getSocialMediaAccountType().PATREON:
                iconName = "patreon";
                break;

            default:
                return null;
        }

        return iconName;
    },

    getGenders() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/genders')
                .then(result => {
                    let genderList = result.data._embedded.genders;

                    genderList.map((gender) => {
                        const parts = gender._links.self.href.split('/');
                        gender.id = parts[parts.length - 1];
                        return gender;
                    })

                    resolve(genderList)
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getCountries() {
        return new Promise((resolve, reject) => {
            ApiService.getAuthenticatedInstance().get('/countries?size=1000')
                .then(result => {
                    let countries = result.data._embedded.countries;

                    countries.map((country) => {
                        const parts = country._links.self.href.split('/');
                        country.id = parts[parts.length - 1];
                        return country;
                    });

                    resolve(countries)
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    async getNavigatorLocation() {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    var pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };

                    resolve(pos);
                }, () => {
                    //Geolocation service failed
                    resolve(null);
                });
            } else {
                // Browser doesn't support Geolocation
                resolve(null);
            }
        });
    },

    getGoogleGeocode(lat, lng) {
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MapService.getGoogleMapKey()}&loading=async`)
            .then(res => res.json())
            .then(response => {
                return response;
            })
            .catch(status => {
                console.log('Request failed.  Returned status of', status)
                return null;
            })
    },

    // getUserCountryCode() {
    //     return new Promise((resolve, reject) => {
    //         fetch(`https://api.ipify.org?format=json`)
    //         .then(async response => {
    //             const data = await response.json();

    //             fetch(`http://www.geoplugin.net/json.gp?ip=${data.ip.toString()}`)
    //             .then(res => res.json())
    //             .then(response => {
    //                 resolve(response.geoplugin_countryCode);
    //             })
    //             .catch((data, status) => {
    //                 console.log('Request failed');
    //                 resolve(null);
    //             })                
    //         })
    //         .catch(() => {
    //             console.log('Request failed');
    //             resolve(null);
    //         })
    //     })
    // },
};

export default ApiService;

export function hasPermission(permissionName) {
    if (currentUser && currentUser.permissions) {
        return currentUser.permissions.indexOf(permissionName) > -1;
    } else {
        return false;
    }
};

export function hasRole(roleName) {
    if (currentUser && currentUser.roles) {
        return currentUser.roles.indexOf(roleName) > -1;
    } else {
        return false;
    }
};